.terms {
  color: white;

  * {
    font-size: 14px;
    line-height: 18px;
  }
  ol li {
    padding-left: inherit;
  }

  .legal-section-header {
    font-size: 20px;
    margin-bottom: 16px;
    margin-top: 24px;
    font-weight: 600;
  }
}
